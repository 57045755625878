<template>
  <div class="login">
    <div class="loginForm">
      <!-- 登录框文字 -->
      <h4 class="appName">{{ companyName }} {{ appNameCn }}</h4>
      <!-- 用户名 -->
      <div class="userName inputView">
        <i class="iconfont icon-denglu"></i>
        <input
          type="text"
          placeholder="请输入用户名"
          v-model="username"
          @blur="inputBlur('username')"
          @keydown.enter="loginSubmit()"
        />
        <span class="error" v-show="showForm.username">* 用户名不能为空</span>
      </div>
      <!-- 密码 -->
      <div class="password inputView">
        <i class="iconfont icon-lock-outline"></i>
        <input
          type="password"
          placeholder="请输入密码"
          v-model="password"
          @blur="inputBlur('password')"
          @keydown.enter="loginSubmit()"
        />
        <span class="error" v-show="showForm.password">* 密码不能为空</span>
      </div>
      <!-- 验证码 -->
      <div class="code">
        <div class="codeInput inputView">
          <i class="iconfont icon-ioskeypadoutline"></i>
          <input
            type="text"
            placeholder="请输入验证码"
            v-model="captcha"
            @blur="inputBlur('captcha')"
            @keydown.enter="loginSubmit()"
          />
          <span class="error" v-show="showForm.captcha">* 验证码不能为空</span>
        </div>
        <!-- 验证码图片 -->
        <img :src="captchaBase64" @click="getcaptchaBase64" />
      </div>
      <!-- 登录按钮 -->
      <div class="loginBtn" @click="loginSubmit">登录</div>
      <!-- 版权信息 -->
      <div class="Copyright" v-html="loginPageCopyright"></div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import sha256 from "js-sha256";
import {
  iotadmincaptchagetdo,
  ssoauthvalidatedo,
  loginpageparmsgetdo,
  ssologin,
} from "@/api/account.js";
export default {
  name: "LoginPage",
  components: {},
  data() {
    return {
      appName: "",
      appNameCn: "",
      companyName: "",
      loginPageCopyright: "",
      faviconUrl: "",
      indexUrl: "",
      captchaBase64: "",
      captchaKey: "",
      username: "",
      password: "",
      captcha: "",
      showForm: {
        username: false,
        password: false,
        captcha: false,
      },
    };
  },
  created() {
    this.getOptionData();
    this.getcaptchaBase64();
  },
  methods: {
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      } else {
        return "";
      }
    },
    //  验证登录是否过期&&获取登录信息
    getOptionData() {
      window.localStorage.setItem("hrefs", this.GetQueryString("hrefs"));
      // 验证登录是否过期
      ssoauthvalidatedo().then((res) => {
        if (res.success) {
          // token未失效，直接跳转回去
          window.location.href =
            window.localStorage.getItem("hrefs") +
            `?token=${window.localStorage.getItem("sso-token")}`;
        } else {
          // token失效，删除token
          window.localStorage.removeItem("sso-token");
        }
      });
      // 获取登录信息
      loginpageparmsgetdo({
        url: window.localStorage.getItem("hrefs"),
      }).then((res) => {
        if (res.failed) return;
        this.appName = res.data.appName; // appname
        this.appNameCn = res.data.appNameCn; // appname中文
        this.companyName = res.data.companyName; // 公司名称
        this.faviconUrl = res.data.faviconUrl; // 页标签图标
        this.loginPageCopyright = res.data.loginPageCopyright; // 版权信息
        this.indexUrl = res.data.indexUrl; // 首页地址
        // 修改页面图标
        document.querySelector('link[rel="shortcut icon"]').href =
          this.faviconUrl;
      });
    },
    // 获取验证码图片
    getcaptchaBase64() {
      iotadmincaptchagetdo().then((res) => {
        this.captchaBase64 = res.data.captchaBase64;
        this.captchaKey = res.data.key;
      });
    },
    // 非空校验
    inputBlur(key) {
      if (!this[key]) {
        this.showForm[key] = true;
        return false;
      } else {
        this.showForm[key] = false;
        return true;
      }
    },
    // 点击登录
    loginSubmit() {
      // 判断是否校验通过
      if (
        !this.inputBlur("username") ||
        !this.inputBlur("password") ||
        !this.inputBlur("captcha")
      )
        return;
      // 获取时间戳
      let timestamp = new Date().getTime();
      let passwords = sha256(md5(this.password) + timestamp); // 密码加密
      // 调用登录接口
      ssologin({
        appName: this.appName,
        captcha: this.captcha,
        key: this.captchaKey,
        username: this.username,
        password: passwords,
        timestamp: timestamp,
      }).then((res) => {
        if (res.success) {
          // 登录成功，保存token并跳转
          // window.localStorage.setItem("sso-username", res.data.username);
          window.localStorage.setItem("sso-token", res.data.token);
          const ssoIndexUrl = window.localStorage.getItem("hrefs")
            ? window.localStorage.getItem("hrefs")
            : this.indexUrl;
          window.location.href = ssoIndexUrl + `?token=${res.data.token}`;
        } else {
          // 登录失败，重新获取验证码图片，弹框提示登录失败信息
          this.getcaptchaBase64();
          this.$tips({
            msg: res.resultMsg,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("@/assets/css/font/iconfont.css");
.login {
  width: 100vw;
  height: 100vh;
  background-image: url("@/assets/img/bg_big.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  .loginForm {
    position: fixed;
    width: 320px;
    height: 350px;
    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    .appName {
      width: 100%;
      height: 40px;
      margin: 0 0 10px;
      font-size: 25px;
      font-weight: 400;
      color: #d8d8d8;
    }
    .inputView {
      position: relative;
      display: flex;
      width: 100%;
      height: 40px;
      margin-bottom: 20px;
      border-radius: 5px;
      line-height: 40px;
      border: 1px solid #3e525e;
      background-color: rgba(255, 255, 255, 0.05);
      i {
        width: 20px;
        height: 100%;
        margin: 0 10px;
        font-size: 20px;
        color: #a0a6b5;
      }
      input {
        flex: 1;
        border: 0;
        outline-style: none;
        border-radius: 5px;
        background-color: transparent;
        color: #fff;
        font-size: 16px;
      }
    }
    .code {
      width: 100%;
      height: 40px;
      margin-bottom: 20px;
      display: flex;
      .codeInput {
        position: relative;
        flex: 1;
        display: flex;
        border: 1px solid #3e525e;
        line-height: 40px;
        background-color: rgba(255, 255, 255, 0.05);
        margin-right: 10px;
        i {
          width: 20px;
          height: 100%;
          margin: 0 10px;
          font-size: 20px;
          color: #a0a6b5;
        }
        input {
          width: 0;
          flex: 1;
          border: 0;
          outline-style: none;
          border-radius: 5px;
          background-color: transparent;
          color: #fff;
          font-size: 16px;
        }
      }
      img {
        display: block;
        width: 100px;
        cursor: pointer;
      }
    }
    .loginBtn {
      width: 100%;
      height: 40px;
      background-color: #2d8cf0;
      color: #fff;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      border-radius: 5px;
      margin-bottom: 20px;
    }
    .Copyright {
      color: #515a6e;
      font-size: 14px;
    }
    .error {
      position: absolute;
      top: 30px;
      left: 10px;
      font-size: 14px;
      color: #ed4014;
    }
  }
}
</style>

<style>
input:-webkit-autofill {
  -webkit-text-fill-color: #fff;
  transition: background-color 5000000000000000000000000000s ease-in-out 0s;
}
a {
  color: #515a6e;
  background: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
</style>
