import request from "@/plugins/request";

//-----------------------登录验证码---------------------------//
export function iotadmincaptchagetdo(data) {
  return request({
    url: "/sso/captcha/get.do",
    method: "get",
    params: data,
  });
}

//-----------------------登录过期状态校验---------------------------//
export function ssoauthvalidatedo(data) {
  return request({
    url: "/sso/auth/validate.do",
    method: "get",
    data,
  });
}

//-----------------------获取登录参数---------------------------//
export function loginpageparmsgetdo(data) {
  return request({
    url: "/sso/login/page/parms/get.do",
    method: "get",
    params: data,
  });
}

//-----------------------登录---------------------------//
export function ssologin(data) {
  return request({
    url: "/sso/login.do",
    method: "post",
    data,
  });
}
