<template>
  <div class="notic_box">{{ msg }}</div>
</template>

<script>
export default {
  data() {
    return {
      msg: "GHUI",
    };
  },
  mounted() {
    setTimeout(() => {
      this.$el.remove();
    }, 1500);
  },
};
</script>

<style scoped="scoped">
.notic_box {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  padding: 10px 20px;
  color: #4f596c;
  background: #e5e9ec;
  border-radius: 5px;
  z-index: 9;
  animation: notictr 0.3s;
}

@keyframes notictr {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 20px;
    opacity: 1;
  }
}
</style>
